import React from 'react'
import { Card, H5 } from '../../lib/components'
import { Link } from 'react-router-dom'

const WithdrawalCard = () => {
  return (
    <Card classes={['info-card-withdrawal']}>
      <div className='info-card-heading'>
        <H5 text='Withdraw' />
      </div>
      <div className='info-card-body'>
        <div className='payment-warning-container'>
          <div>
            This feature will be added soon. For now, please contact &nbsp;
            <Link className='support-link' to={'/contact-us'}>
              Credibila Support
            </Link>
            &nbsp; for any withdrawal-related inquiries.
          </div>
        </div>
      </div>
    </Card>
  )
}

export default WithdrawalCard
