import { useCallback, useEffect, useRef, useState } from 'react'
import { XS, SM, MD, LG, XL, XXL, breakpoints } from './constants'
interface InitialValue {
  width: number
  height: number
}
const useBreakpoint = () => {
  const [breakpoint, setBreakPoint] = useState<number>()
  const [breakpointRange, setBreakPointRange] = useState('')
  const [windowSize, setWindowSize] = useState<InitialValue>({
    width: 0,
    height: 0,
  })

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    if (0 < windowSize.width && windowSize.width <= XS) {
      setBreakPoint(windowSize.width)
      setBreakPointRange('xs')
    }
    if (XS < windowSize.width && windowSize.width <= SM) {
      setBreakPoint(windowSize.width)
      setBreakPointRange('sm')
    }
    if (SM < windowSize.width && windowSize.width <= MD) {
      setBreakPoint(windowSize.width)
      setBreakPointRange('md')
    }
    if (MD < windowSize.width && windowSize.width <= LG) {
      setBreakPoint(windowSize.width)
      setBreakPointRange('lg')
    }
    if (LG < windowSize.width && windowSize.width <= XL) {
      setBreakPoint(windowSize.width)
      setBreakPointRange('xl')
    }
    if (windowSize.width >= XL) {
      setBreakPoint(windowSize.width)
      setBreakPointRange('xxl')
    }

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize.width])
  return { breakpoint, breakpointRange }
}

function useDebounce<T extends (...args: any[]) => void>(callback: T, delay: number): T {
  const timeoutRef = useRef<number | undefined>()

  const debouncedCallback = useCallback(
    (...args: Parameters<T>) => {
      if (timeoutRef.current !== undefined) {
        window.clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = window.setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay],
  )

  return debouncedCallback as T
}

export { useBreakpoint, useDebounce }
