import React from 'react'
import InvestmentDetailsCard from './investments-details-card'
import InvestmentGraph from './investment-graph'
import DistributionGraph from './distribution-graph'
import WithdrawalCard from './withdrawal-card'
import {
  DashboardInvestmentDistribution,
  DashboardInvestmentsGraphs,
  DashboardSummary,
} from '../../lib/types'

interface InvestmentDetailsProps {
  dashboardSummary: DashboardSummary
  investmentsData: DashboardInvestmentsGraphs
  distributionData: DashboardInvestmentDistribution | undefined
  isDashboardInvestmentView: boolean | undefined
  totalInvestments?: number
}

const InvestmentDetails = ({
  dashboardSummary,
  investmentsData,
  distributionData,
  isDashboardInvestmentView,
}: InvestmentDetailsProps) => {
  return (
    <div className='investment-details-container'>
      <div className='investment-details-container-card-total-investments'>
        <InvestmentDetailsCard
          cardText='total-investments'
          value={
            isNaN(Number(dashboardSummary?.totalInvestedAmount))
              ? 0
              : Number(dashboardSummary?.totalInvestedAmount)
          }
        />
      </div>
      <div className='investment-details-container-card-total-returns'>
        <InvestmentDetailsCard
          cardText='total-returns'
          value={
            isNaN(Number(dashboardSummary?.totalReturnsTillDate))
              ? 0
              : Number(dashboardSummary?.totalReturnsTillDate)
          }
        />
      </div>
      <div className='investment-details-container-card-realized-gains'>
        <InvestmentDetailsCard
          cardText='realized-gains'
          value={
            isNaN(Number(dashboardSummary?.realizedGains))
              ? 0
              : Number(dashboardSummary?.realizedGains)
          }
        />
      </div>
      <div className='investment-details-container-card-unrealized-gains'>
        <InvestmentDetailsCard
          cardText='unrealized-gains'
          value={
            isNaN(Number(dashboardSummary?.unrealizedGains))
              ? 0
              : Number(dashboardSummary?.unrealizedGains)
          }
        />
      </div>
      <div
        className={`investment-details-container-card-distribution-graph${
          !isDashboardInvestmentView ? '-2-col' : '-3-col'
        }`}
      >
        <InvestmentGraph
          investmentsData={investmentsData}
          isDashboardInvestmentView={isDashboardInvestmentView}
        />
      </div>
      {!isDashboardInvestmentView ? (
        <div className='investment-details-container-card-investment-distribution'>
          <DistributionGraph distributionData={distributionData} />
        </div>
      ) : (
        <div className='investment-details-container-card-withdrawal'>
          <WithdrawalCard />
        </div>
      )}
    </div>
  )
}

export default InvestmentDetails
