import React from 'react'
import { BasicStepper, Card, H6 } from '../../lib/components'
import {
  displayDollar,
  displayPercentage,
  displayReturnsTitle,
  displayStatus,
} from '../../lib/utils/helpers'
import { ASSET_CLASSES } from '../../lib/utils/constants'
import { investmentStep } from '../../lib/utils/message'

interface InvestmentCardProps {
  projectName: string
  investmentStatus: string
  investmentAmount: string
  irr: string
  ticketSize: string
  currencyCode: string
  assetClass: string
  agreement: string
  paymentCopy: string
  fundAllocation?: string
  activeStep: number
  transactionId?: string
}

function OpportunityCard({
  projectName,
  investmentStatus,
  investmentAmount,
  irr,
  ticketSize,
  currencyCode,
  assetClass,
  activeStep,
  transactionId,
}: InvestmentCardProps) {
  //   useEffect(() => {
  //     const handleStepperClass = () => {
  //       const elements: any = document.getElementsByClassName('basic-stepper-item')
  //       for (let i = 0; i < elements.length; i++) {
  //         if (elements[i] && elements[i].firstChild) {
  //           if (
  //             elements[i].firstChild.className ===
  //             'basic basic-step-counter completed  undefined regular'
  //           ) {
  //             elements[i].classList.add('completed-basic-stepper-item')
  //           }
  //           if (
  //             elements[i].firstChild.className ===
  //             'basic basic-step-counter active  undefined regular'
  //           ) {
  //             elements[i].classList.add('active-basic-stepper-item')
  //           }
  //         }
  //       }
  //     }
  //     handleStepperClass()
  //   }, [activeStep])

  return (
    <Card classes={['opportunity-card-container']}>
      <div className='opportunity-card-header'>
        <div className='opportunity-card-header-opportunity-name-col'>
          <H6 text={projectName} />
          <div className='opportunity-card-header-opportunity-status-container'>
            <p className='opportunity-card-status'>{assetClass}</p>
            <div className='opportunity-card-header-opportunity-status'>
              <p className='opportunity-card-header-opportunity-status-label'>Status</p>
              <p
                className={`opportunity-card-header-opportunity-status-chip ${
                  investmentStatus === 'REJECTED' || investmentStatus === 'CLOSED'
                    ? 'status-chip-error'
                    : 'status-chip'
                }`}
              >
                {displayStatus(investmentStatus)}
              </p>
            </div>
          </div>
        </div>

        <div className='opportunity-card-header-opportunity-stats-col'>
          {assetClass !== ASSET_CLASSES.LISTED_EQUITIES && irr !== '0.00' && (
            <div className='opportunity-card-investment-stats-card'>
              <p className='opportunity-card-investment-card-main'>
                {assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                  ? displayDollar(irr, currencyCode)
                  : displayPercentage(irr) || ''}
              </p>
              <p className='opportunity-card-investment-card-sub'>
                {displayReturnsTitle(assetClass)}
              </p>
            </div>
          )}
          <div className='opportunity-card-investment-stats-card'>
            <p className='opportunity-card-investment-card-main'>
              {displayDollar(ticketSize, currencyCode)}
            </p>
            <p className='opportunity-card-investment-card-sub'>Ticket Size</p>
          </div>
        </div>
      </div>

      <div className='opportunity-card-body'>
        <BasicStepper
          forceFullView
          steps={investmentStep}
          activeStep={activeStep}
          showStatus={true}
        />
      </div>

      <div className='opportunity-card-footer'>
        {transactionId && (
          <div className='opportunity-card-footer-transaction-col'>
            <div className='opportunity-card-footer-transaction-col-text'>
              Transaction ID: &nbsp;
            </div>
            <div className='opportunity-card-footer-transaction-col-value'>{transactionId}</div>
          </div>
        )}
        <div className='opportunity-card-footer-investment-amount-col'>
          <div className='opportunity-card-footer-investment-amount-col-text'>
            Total Amount: &nbsp;
          </div>
          <div className='opportunity-card-footer-investment-amount-col-value'>
            {displayDollar(investmentAmount, currencyCode, 2, true)}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default OpportunityCard
