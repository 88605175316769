import React, { useRef } from 'react'
import { LoginPresenter } from './login-presenter'
import { signInSchema as loginSchema } from '../../lib/utils/schema'
import { Card, H2, Flex } from '../../lib/components'
import { userServices } from '../../services'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import { createConfiguration } from '../../global-state/investor-slice'
import { showBanner } from '../../global-state/banner-slice'
import { useNavigate } from 'react-router-dom'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM, EVENTS } from '../../lib/utils/constants'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { getInvestorDataFromSS } from '../../lib/utils/local-storage'
import { EngagementEvents, logCrmEvent } from '../../lib/utils/crmEvents'

const initialValues = {
  email: '',
  password: '',
  rememberMe: '',
}

const LoginContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const reRef = useRef<ReCAPTCHA>(null)
  const { breakpoint } = useBreakpoint()
  const { authToken, investorData } = getInvestorDataFromSS()

  const handleSubmit_v1 = async (val: any) => {
    const token = await reRef.current?.executeAsync()
    reRef.current?.reset()
    if (!authToken && !investorData) {
      const user: any = await userServices.userLogin_v1(
        {
          email: val.email?.toLowerCase(),
          password: val.password,
          role: 'INVESTOR',
        },
        { 'g-recaptcha-response': token ? token : '' },
      )
      if (!!user && user.status === 200 && user?.data?.role === 'INVESTOR') {
        dispatch(
          createConfiguration({
            isEmailVerified: user.data.is_verified,
            ...user.data,
            // TODO: Remove rememberMe value
            rememberMe: val.rememberMe ? true : false,
          }),
        )

        if (user.data.is_verified) {
          dispatch(
            showBanner({
              text: 'Logged in successfully',
              variant: 'success-banner',
              duration: 1000,
            }),
          )
        }
        captureEvent(EVENTS.user_logged_in, {
          email: val.email,
        })

        logCrmEvent(EngagementEvents.logged_in, { Authorization: authToken ?? '' })

        navigate('/')
      } else if (!!user && user.status === 200 && user?.data?.role !== 'INVESTOR') {
        dispatch(showBanner({ text: 'Not allowed to login!', variant: 'error-banner' }))
      } else if (!!user && user.status === 401 && user.data.code === 'wrong-user-source') {
        dispatch(showBanner({ text: 'Try login using google', variant: 'error-banner' }))
      } else {
        dispatch(
          showBanner({
            text: (user.data && user.data.message) || 'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
      }
    } else {
      dispatch(
        showBanner({
          text: 'You already logged in another tab. Please logout and use another account.',
          variant: 'error-banner',
          duration: 3000,
        }),
      )
    }
  }

  const googleLoginHandler = async (res: any, rememberMe: any) => {
    const { tokenId } = res
    if (!authToken && !investorData) {
      const user: any = await userServices.googleSignIn_v1({
        accessToken: tokenId,
        role: 'INVESTOR',
      })
      if (!!user && user.status === 200 && user?.data?.role === 'INVESTOR') {
        dispatch(createConfiguration({ ...user.data, rememberMe: rememberMe }))

        if (user.data.isEmailVerified) {
          dispatch(
            showBanner({
              text: 'Logged in successfully',
              variant: 'success-banner',
              duration: 1000,
            }),
          )
        }

        logCrmEvent(EngagementEvents.logged_in, { Authorization: authToken ?? '' })

        navigate('/')
      } else if (!!user && user.status === 200 && user?.data?.role !== 'INVESTOR') {
        dispatch(showBanner({ text: 'Not allowed to login!', variant: 'error-banner' }))
      } else {
        dispatch(
          showBanner({
            text: (user.data && user.data.message) || 'Something went wrong! Please try again.',
            variant: 'error-banner',
          }),
        )
      }
    } else {
      dispatch(
        showBanner({
          text: 'You already logged in another tab. Please logout and use another account.',
          variant: 'error-banner',
        }),
      )
      window.location.reload()
    }
  }

  return (
    <Flex variant='column-center'>
      <H2 text={'Log In'} classes={['mb-48']} />
      <Card classes={breakpoint && breakpoint <= SM ? ['p-16'] : ['p-40']}>
        <LoginPresenter
          initialValues={initialValues}
          validationSchema={loginSchema}
          // handleFormSubmit={handleSubmit}
          handleFormSubmit={handleSubmit_v1}
          reRef={reRef}
          googleLoginHandler={googleLoginHandler}
        />
      </Card>
    </Flex>
  )
}

export { LoginContainer }
