import React from 'react'
import { Card, Flex } from '../../lib/components'
import { displayDollar } from '../../lib/utils/helpers'
import {
  RealizedGainsIcon,
  TotalInvestmentIcon,
  TotalReturnsIcon,
  UnrealizedGainsIcon,
  ProfitArrowIcon,
  LossArrowIcon,
} from '../../lib/components/Icon'

const getIcon = (cardText: string) => {
  const iconsMap: Record<string, JSX.Element> = {
    'total-investments': <TotalInvestmentIcon />,
    'total-returns': <TotalReturnsIcon />,
    'realized-gains': <RealizedGainsIcon />,
    'unrealized-gains': <UnrealizedGainsIcon />,
  }
  return iconsMap[cardText] || <TotalInvestmentIcon />
}

const getText = (cardText: string) => {
  const textMap: Record<string, string> = {
    'total-investments': 'Total Amount Invested',
    'total-returns': 'Total Returns',
    'realized-gains': 'Realized Gains',
    'unrealized-gains': 'Unrealized Gains',
  }
  return textMap[cardText]
}

const getArrowDirection = (value: string | number, cardText: string, classes?: string[]) => {
  const numericValue = parseFloat(value as string)
  if (cardText === 'total-returns' || cardText === 'realized-gains') {
    if (numericValue === 0) return ''
    return numericValue > 0 ? (
      <ProfitArrowIcon classes={classes} />
    ) : (
      <LossArrowIcon classes={classes} />
    )
  }
  return ''
}

const getDisplayValue = (value: string | number, cardText: string): string => {
  const numericValue = parseFloat(value as string)
  if (cardText === 'realized-gains' || cardText === 'unrealized-gains') {
    return `${numericValue.toFixed(2)}%`
  }
  return displayDollar(value as string, 'USD', 2, true)
}

const getTextColorClass = (value: string | number, cardText: string) => {
  const numericValue = parseFloat(value as string)
  if (numericValue === 0 || cardText === 'total-investments' || cardText === 'unrealized-gains')
    return ''

  return numericValue > 0 ? 'text-color-profit' : 'text-color-loss'
}

interface InvestmentDetailsCardProps {
  cardText: string
  value: string | number
  isDashboardInvestmentView?: boolean
  totalInvestments?: number
}

const InvestmentDetailsCard = ({ cardText, value }: InvestmentDetailsCardProps) => {
  return (
    <Card classes={['investment-details-card']}>
      <div className='investment-details-card-container'>
        <div className={`investment-details-card-icon-circle-${cardText}`}>{getIcon(cardText)}</div>
        <Flex variant='column' classes={['investment-details-card-text-content', 'justify-center']}>
          <div className={`investment-details-card-value ${getTextColorClass(value, cardText)}`}>
            {getDisplayValue(value, cardText)}
            {getArrowDirection(value, cardText, ['ml-8'])}
          </div>
          <div className='investment-details-card-text'>{getText(cardText)}</div>
        </Flex>
      </div>
    </Card>
  )
}

export default InvestmentDetailsCard
