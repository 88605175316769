import React, { useState } from 'react'
import {
  H4,
  P,
  Flex,
  ProgressBar,
  Span,
  Anchor,
  PendingIcon,
  ApprovedIcon,
  DeniedIcon,
} from '../../lib/components'
import { calculatePercentage, convertNumberWithCommas } from '../../lib/utils/helpers'
import { ContactUsModal } from '../../lib/components/ContactUsModal'

interface DashboardInfoSectionProps {
  kycStatus: string | undefined
  email: string
  name: string
  userInvestmentDetail: any
}

const DashboardInfoSection = ({
  kycStatus,
  email,
  name,
  userInvestmentDetail,
}: DashboardInfoSectionProps) => {
  const [showContactUsModal, setShowContactUsModal] = useState(false)
  const getFirstName = (name: string) => {
    return name.split(' ')[0]
  }
  const nearingInvestmentLimit =
    userInvestmentDetail?.totalInvestment &&
    userInvestmentDetail?.investmentLimit &&
    calculatePercentage(
      userInvestmentDetail?.totalInvestment,
      userInvestmentDetail?.investmentLimit,
    ) > 90

  return (
    <div className='dashboard-top-section-wrapper'>
      <div className='dashboard-top-section-container-new'>
        {(email || name) && <H4 text={`Hello ${name?.length > 0 ? getFirstName(name) : email}`} />}
        <div className='dashboard-top-section-container-new-sections'>
          <div className='dashboard-top-section-left-new'>
            <Flex variant='space-between' classes={['investment-container']}>
              <Span classes={['investment-limit']} variant='bold' text='Investment Limit' />
              <span className='investment-limit-values'>
                <strong>{`$${
                  userInvestmentDetail && userInvestmentDetail.totalInvestment
                    ? convertNumberWithCommas(parseInt(userInvestmentDetail.totalInvestment) / 100)
                    : '0'
                }`}</strong>
                {`/$${
                  userInvestmentDetail && userInvestmentDetail.investmentLimit
                    ? convertNumberWithCommas(parseInt(userInvestmentDetail?.investmentLimit) / 100)
                    : '0'
                }`}
              </span>
            </Flex>
            <ProgressBar
              variant='default-bar'
              maxValue={'100'}
              value={
                userInvestmentDetail &&
                userInvestmentDetail?.totalInvestment &&
                userInvestmentDetail?.investmentLimit
                  ? Math.ceil(
                      calculatePercentage(
                        userInvestmentDetail?.totalInvestment.toString(),
                        userInvestmentDetail?.investmentLimit.toString(),
                      ),
                    ).toString()
                  : '0'
              }
            />
            {(userInvestmentDetail.length === 0 || nearingInvestmentLimit) && (
              <>
                <Span
                  classes={['mt-16']}
                  variant='thin'
                  text={nearingInvestmentLimit && 'Your investment limit is soon to be reached.'}
                />
                <span className={'mt-4 basic-anchor'} onClick={() => setShowContactUsModal(true)}>
                  {' '}
                  Request extension of the limit{' '}
                </span>
              </>
            )}

            {showContactUsModal ? (
              <ContactUsModal
                active={true}
                subject={'General'}
                handleOnClose={() => setShowContactUsModal(false)}
              />
            ) : null}
          </div>

          <div className='dashboard-top-section-right-new'>
            <div className='dashboard-top-section-right-new-status-container'>
              <H4 text={'KYC'} />
              <span className={`kyc-badge-${kycStatus} kyc-status-container-status-${kycStatus}`}>
                {kycStatus === 'NOT_STARTED' && (
                  <>
                    <PendingIcon height='21' width='21' /> Not Started
                  </>
                )}
                {kycStatus === 'INITIATED' && (
                  <>
                    <PendingIcon height='21' width='21' /> Initiated
                  </>
                )}
                {kycStatus === 'PENDING' && (
                  <>
                    <PendingIcon height='18' width='20' /> Sent For Approval
                  </>
                )}
                {kycStatus === 'REJECTED' && (
                  <>
                    <DeniedIcon height='18' width='20' /> Rejected
                  </>
                )}
                {kycStatus === 'RESUBMIT' && (
                  <>
                    <DeniedIcon height='18' width='20' /> Resubmit
                  </>
                )}
                {kycStatus === 'APPROVED' && (
                  <>
                    <ApprovedIcon height='18' width='20' /> Approved
                  </>
                )}
                {kycStatus === 'SENT_FOR_APPROVAL' && (
                  <>
                    <PendingIcon height='21' width='21' /> Sent For Approval
                  </>
                )}
              </span>
            </div>
            <div className='dashboard-top-section-right-new-content-container'>
              {(kycStatus === 'NOT_STARTED' || kycStatus === 'INITIATED') && (
                <div>
                  <P
                    text={
                      <>
                        Your KYC is not completed. Please complete the KYC for investing in the
                        opportunities. &nbsp;
                        <Anchor
                          variant='basic-anchor'
                          text={kycStatus === 'NOT_STARTED' ? 'Start KYC' : 'Continue KYC'}
                          href='/profile/kyc'
                        />
                        .
                      </>
                    }
                  />
                </div>
              )}
              {kycStatus === 'SENT_FOR_APPROVAL' && (
                <div>
                  <P
                    text={
                      <>
                        Your KYC has been submitted for approval and will be processed within the
                        next 24 hours. Once approved, you will be able to invest in the &nbsp;
                        <Anchor variant='basic-anchor' text='opportunities' href='/opportunities' />
                        .
                      </>
                    }
                  />
                </div>
              )}
              {kycStatus === 'REJECTED' && (
                <div>
                  <P
                    text={
                      <>
                        Your KYC was rejected. Please complete the KYC process again by clicking
                        &nbsp;
                        <Anchor variant='basic-anchor' text='Start KYC' href='/profile/kyc' />.
                      </>
                    }
                  />
                </div>
              )}
              {kycStatus === 'APPROVED' && (
                <div>
                  <P
                    text={
                      <>
                        You can now go ahead to browse through &nbsp;
                        <Anchor variant='basic-anchor' text='opportunities' href='/opportunities' />
                        .
                      </>
                    }
                  />
                </div>
              )}
              {kycStatus === 'RESUBMIT' && (
                <div>
                  <P
                    text={
                      <>
                        Your KYC needs resubmission. Please complete the KYC process to invest in
                        the opportunities. &nbsp;
                        <Anchor variant='basic-anchor' text='Resubmit KYC' href='/profile/kyc' />.
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { DashboardInfoSection }
