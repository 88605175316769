import { jwtDecode } from 'jwt-decode'

import { SupportedFileType } from '../lib/types'
import { SUPPORTED_FILE_UPLOAD_MIME_TYPES } from '../lib/utils/constants'

export const generateSupportedTypeMessage = (types: SupportedFileType[]): string => {
  const acceptedTypes = types.map((type) => {
    if (type === 'image') {
      return '.png, .jpg, .jpeg'
    }
    return SUPPORTED_FILE_UPLOAD_MIME_TYPES[type]
  })
  return `Supported file type: ${acceptedTypes.join(', ')}`
}

export const generateMimeAcceptString = (types: SupportedFileType[]): string => {
  const acceptedTypes = types.map((type) => SUPPORTED_FILE_UPLOAD_MIME_TYPES[type])
  return acceptedTypes.join(', ')
}

interface DecodedToken {
  exp: number
}

export const isTokenExpired = (token: string): boolean => {
  if (!token) return true

  const decoded = jwtDecode<DecodedToken>(token)
  const currentTime = Date.now() / 1000

  return decoded.exp < currentTime
}
