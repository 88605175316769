import React, { useState } from 'react'
import { Card, H5, P } from '../../lib/components'
import { LineChartCard } from './chart-card'
import { NextArrow, PreviousArrow } from '../../lib/components/Icon'
import { useNavigate } from 'react-router-dom'
import { DashboardInvestmentsGraphs } from '../../lib/types/propsType_v1'

const InvestmentGraph = ({
  investmentsData,
  isDashboardInvestmentView,
}: {
  investmentsData: DashboardInvestmentsGraphs
  isDashboardInvestmentView: boolean | undefined
}) => {
  const navigate = useNavigate()
  const [currentInvestment, setCurrentInvestment] = useState<number>(0)
  const maxTitleLength = 40
  const opportunityTitle =
    investmentsData?.investments[currentInvestment]?.[0]?.opportunity_title ?? ''

  const handlePrevClick = () => {
    setCurrentInvestment((prev) => prev - 1)
  }

  const handleNextClick = () => {
    setCurrentInvestment((prev) => prev + 1)
  }

  const handleGraphClick = () => {
    if (isDashboardInvestmentView) return
    const opportunityId = investmentsData?.investments[currentInvestment]?.[0]?.opportunity_id
    navigate(`/dashboard/investments/${opportunityId}`)
  }

  return (
    <Card classes={['info-card']}>
      <div className='info-card-heading'>
        <H5 text={isDashboardInvestmentView ? 'Your Investment' : 'Your Investments'} />
      </div>
      <div className='info-card-body'>
        {opportunityTitle.length > 0 &&
          investmentsData?.investments?.length > 0 &&
          !isDashboardInvestmentView && (
            <div className='investment-graph-card-switcher-container'>
              <button
                onClick={handlePrevClick}
                disabled={currentInvestment === 0 || investmentsData?.investments?.length === 1}
              >
                <PreviousArrow />
              </button>
              <div className='cursor-pointer' onClick={handleGraphClick}>
                <P
                  variant='center'
                  text={
                    opportunityTitle.length > maxTitleLength
                      ? opportunityTitle.slice(0, maxTitleLength) + '...'
                      : opportunityTitle
                  }
                  classes={['investment-graph-card-switcher-title']}
                />
              </div>
              <button
                onClick={handleNextClick}
                disabled={
                  currentInvestment === (investmentsData?.investments?.length ?? 0) - 1 ||
                  investmentsData?.investments?.length === 1
                }
              >
                <NextArrow />
              </button>
            </div>
          )}
        <div onClick={handleGraphClick}>
          <LineChartCard
            emptyMessage="No data to show. Looks like you don't have any holdings."
            data={investmentsData?.investments[currentInvestment]}
            isDashboardInvestmentView={isDashboardInvestmentView}
          />
        </div>
      </div>
    </Card>
  )
}

export default InvestmentGraph
