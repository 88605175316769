import React, { useEffect, useState } from 'react'
import { displayDollar } from '../../lib/utils/helpers'
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Cell,
  Pie,
  PieChart,
  Text,
  Customized,
} from 'recharts'
import './dashboard.scss'
import {
  DashboardInvestmentDistribution,
  DashboardInvestmentsSingleGraph,
} from '../../lib/types/propsType_v1'
import { SM } from '../../lib/utils/constants'
import { useBreakpoint } from '../../lib/utils/hooks'

// Array of month names
const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
// PieChart Colors
const PIECHART_COLORS = ['#1D86FF', '#68ACFA', '#9AC9FF', '#6599D4', '#E0EFFF', '#BADAFF']

interface LineChartCardProps {
  data: DashboardInvestmentsSingleGraph[]
  emptyMessage?: string
  isDashboardInvestmentView: boolean | undefined
}

interface PieChartCardProps {
  data: DashboardInvestmentDistribution | undefined
  emptyMessage?: string
}

interface TooltipPayload {
  payload: DashboardInvestmentsSingleGraph
}

const CustomTooltip: React.FC<{ active?: boolean; payload?: TooltipPayload[] }> = ({
  active,
  payload,
}) => {
  if (active && payload && payload.length) {
    const { currentInvestmentValue } = payload[0].payload

    return (
      <div
        className='custom-tooltip'
        style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}
      >
        <p>{`Current Value (Amount Invested + Returns): ${displayDollar(
          currentInvestmentValue?.toString(),
          'USD',
          2,
          currentInvestmentValue / 100 < 10000,
        )}`}</p>
      </div>
    )
  }

  return null
}

const formatYAxis = (value: number) => {
  return value?.toString()
}

const formatShortDate = (dateString: string) => {
  const date = new Date(dateString)
  const day = date?.getDate()
  const year = date?.getFullYear()?.toString()?.slice(-2)

  const month = MONTH_NAMES[date?.getMonth()]
  return `${month} ${day} '${year}`
}

const LineChartCard = ({
  data,
  emptyMessage = 'No data available',
  isDashboardInvestmentView,
}: LineChartCardProps) => {
  const { breakpoint } = useBreakpoint()
  const [chartYRange, setChartYRange] = useState({ min: 0, max: 1000 })

  useEffect(() => {
    // Calculating max range of Y-axis
    const minValue = Math.min(...(data?.map((item) => item.currentInvestmentValue) || []))
    const maxValue = Math.max(...(data?.map((item) => item.currentInvestmentValue) || []))

    setChartYRange({
      min: minValue - 0.1 * minValue,
      max: maxValue + 0.1 * maxValue,
    })
  }, [data])

  return (
    <div className={`line-chart-container ${!isDashboardInvestmentView ? 'recharts-hover' : ''}`}>
      <ResponsiveContainer width='100%' height={250}>
        <LineChart data={data}>
          {!data || data?.length === 0 ? (
            <Customized
              component={() => {
                return (
                  <Text
                    style={{ transform: 'translate(50%, 50%)', lineHeight: '10.5px' }}
                    {...(breakpoint && breakpoint <= SM && { width: 250 })}
                    textAnchor='middle'
                    verticalAnchor='middle'
                  >
                    {emptyMessage}
                  </Text>
                )
              }}
            />
          ) : (
            <>
              <CartesianGrid strokeDasharray='3 5' />
              <YAxis
                axisLine={false}
                domain={[chartYRange?.min, chartYRange?.max]}
                tickFormatter={formatYAxis}
              />
              <XAxis
                dataKey='date'
                tickFormatter={formatShortDate}
                padding={{ left: 30, right: 20 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign='bottom' height={36} />
              <Line
                type='monotone'
                dataKey='currentInvestmentValue'
                stroke='#0F4F99'
                activeDot={{ r: 8 }}
                name='Investment Value'
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

const PieChartCard = ({ data, emptyMessage = 'No data available' }: PieChartCardProps) => {
  const pieData = data && Object.entries(data)?.map(([name, value]) => ({ name, value }))
  const { breakpoint } = useBreakpoint()
  return (
    <div className='pie-chart-container'>
      <ResponsiveContainer
        width={!pieData || pieData?.length === 0 ? '100%' : '35%'}
        minWidth={200}
        height={250}
        className={'pie-chart'}
      >
        <PieChart>
          {!pieData || pieData?.length === 0 ? (
            <Customized
              component={() => {
                return (
                  <Text
                    style={{ transform: 'translate(50%, 50%)' }}
                    {...(breakpoint && breakpoint <= SM && { width: 250 })}
                    textAnchor='middle'
                    verticalAnchor='middle'
                  >
                    {emptyMessage}
                  </Text>
                )
              }}
            />
          ) : (
            <>
              <Pie
                data={pieData}
                dataKey='value'
                nameKey='name'
                cx='50%'
                cy='50%'
                outerRadius={100}
                innerRadius={50}
                fill='#8884d8'
                label={false}
              >
                {pieData?.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={PIECHART_COLORS[index % PIECHART_COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </>
          )}
        </PieChart>
      </ResponsiveContainer>
      {pieData && pieData?.length > 0 && (
        <div className='pie-chart-legend'>
          {pieData?.map((entry, index) => (
            <div key={`legend-${index}`} className='pie-chart-legend-item'>
              <div
                className='pie-chart-legend-item-marker'
                style={{
                  backgroundColor: PIECHART_COLORS[index % PIECHART_COLORS.length],
                }}
              ></div>
              <div className='pie-chart-legend-item-text'>
                <div className='pie-chart-legend-item-text-value'>{`${entry.value}%`}</div>
                <div className='pie-chart-legend-item-text-asset-name'>{entry.name}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export { LineChartCard, PieChartCard }
