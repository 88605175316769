import React, { useRef, useEffect } from 'react'
import { SignupPresenter } from './signup-presenter'
import { signUpSchema } from '../../lib/utils/schema'
import { Card, H2, Flex } from '../../lib/components'
import { useNavigate, useParams } from 'react-router-dom'

import { userServices } from '../../services'
import { useDispatch } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import { createConfiguration } from '../../global-state/investor-slice'
import ReCAPTCHA from 'react-google-recaptcha'
import { captureEvent } from '../../lib/utils/posthogUtils/usePostHog'
import { EVENTS } from '../../lib/utils/constants'

const initialValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
}

const SignupContainer = () => {
  const reRef = useRef<ReCAPTCHA>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { email } = useParams()
  const handleSubmit = async (val: any) => {
    const token = await reRef.current?.executeAsync()
    reRef.current?.reset()
    const createUser: any = await userServices.userSignUp(
      {
        name: val.name,
        email: val.email,
        password: val.password,
        role: 'INVESTOR',
      },
      { 'g-recaptcha-response': token ? token : '' },
    )
    if (!!createUser && createUser.status === 200 && createUser?.data?.role === 'INVESTOR') {
      dispatch(createConfiguration(createUser.data))
      dispatch(
        showBanner({
          text: 'Please verify your email address',
          variant: 'warning-banner',
        }),
      )
      navigate('/welcome')

      //Capture posthog event when Investor signup
      captureEvent(EVENTS.user_signed_up, {
        email: val.email,
      })
    } else if (!!createUser && createUser.status === 200 && createUser?.data?.role !== 'INVESTOR') {
      dispatch(showBanner({ text: 'Not allowed to sign up!', variant: 'error-banner' }))
    } else {
      dispatch(
        showBanner({
          text:
            (createUser.data && createUser.data.message) ||
            'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleSubmit_v1 = async (val: any) => {
    const token = await reRef.current?.executeAsync()
    reRef.current?.reset()
    const createUser = await userServices.userSignUp_v1(
      {
        name: val.name,
        email: val.email?.toLowerCase(),
        password: val.password,
        role: 'INVESTOR',
      },
      { 'g-recaptcha-response': token ? token : '' },
    )

    const isUserCreationSuccessful =
      !!createUser && createUser.status === 200 && createUser?.data?.role === 'INVESTOR'

    if (isUserCreationSuccessful) {
      dispatch(createConfiguration(createUser.data))
      dispatch(
        showBanner({
          text: 'Please verify your email address',
          variant: 'warning-banner',
        }),
      )
      navigate('/welcome')

      captureEvent(EVENTS.user_signed_up, {
        email: val.email,
      })
    } else if (!!createUser && createUser.status === 200 && createUser?.data?.role !== 'INVESTOR') {
      dispatch(showBanner({ text: 'Not allowed to sign up!', variant: 'error-banner' }))
    } else {
      dispatch(
        showBanner({
          text:
            (createUser.data && createUser.data.message) ||
            'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  const googleLoginHandler = async (res: any) => {
    const { tokenId } = res
    const user: any = await userServices.googleSignIn_v1({
      accessToken: tokenId,
      role: 'INVESTOR',
    })
    if (!!user && user.status === 200 && user?.data?.role === 'INVESTOR') {
      dispatch(createConfiguration(user.data))

      dispatch(showBanner({ text: 'Login Success', variant: 'success-banner' }))
      navigate('/')
    } else if (!!user && user.status === 200 && user?.data?.role !== 'INVESTOR') {
      dispatch(showBanner({ text: 'Not allowed to sign up!', variant: 'error-banner' }))
    } else {
      dispatch(
        showBanner({
          text: (user.data && user.data.message) || 'Something went wrong! Please try again.',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <Flex variant='column-center'>
      <H2 text={'Sign Up'} classes={['mb-48']} />
      <Card classes={['p-40']}>
        <SignupPresenter
          initialValues={{ ...initialValues, email: email || '' }}
          validationSchema={signUpSchema}
          // handleFormSubmit={handleSubmit}
          handleFormSubmit={handleSubmit_v1}
          reRef={reRef}
          googleLoginHandler={googleLoginHandler}
        />
      </Card>
    </Flex>
  )
}

export { SignupContainer }
