import React from 'react'
import { Card, H5 } from '../../lib/components'
import { NoProjectSection } from './no-project-section'
import {
  INVESTMENT_AGREEMENT,
  INVESTMENT_APPROVAL,
  INVESTMENT_REVIEW,
  PAYMENT_CONFIRMATION,
} from '../../utils/constants'
import OpportunityCard from './opportunity-card'

const OpportunityProgressCard = ({
  investments,
  isDashboardInvestmentView,
}: {
  investments: any[]
  isDashboardInvestmentView: boolean | undefined
}) => {
  const handleStepperForInvestment = (investment: {
    metadata: { completedSteps: number }
    status: string
  }): number => {
    switch (investment?.metadata?.completedSteps) {
      case PAYMENT_CONFIRMATION:
        return PAYMENT_CONFIRMATION
      case INVESTMENT_REVIEW:
        return investment.status === 'APPROVED' ? INVESTMENT_APPROVAL : INVESTMENT_REVIEW
      default:
        return INVESTMENT_AGREEMENT
    }
  }

  return (
    <Card classes={['opportunity-list-card']}>
      <div className='opportunity-list-card-heading'>
        <H5 text={'Investments in Progress'} />
      </div>
      <div className='opportunity-list-card-body'>
        {(!investments || investments?.length === 0) && (
          <NoProjectSection
            primaryText={'Looks like you do not have any investments in progress.'}
          />
        )}

        {investments?.length > 0 &&
          investments?.map((investment: any, idx) => {
            const {
              asset_class,
              currency_code,
              amount,
              paymentCopy,
              agreement,
              opportunity_title,
              status,
              investment_return,
              ticket_size,
              fundAllocation,
              transaction_id,
            } = investment
            return (
              <OpportunityCard
                key={idx}
                projectName={opportunity_title}
                investmentStatus={status}
                investmentAmount={amount}
                irr={investment_return}
                ticketSize={ticket_size}
                currencyCode={currency_code}
                assetClass={asset_class}
                agreement={agreement}
                paymentCopy={paymentCopy}
                fundAllocation={fundAllocation}
                transactionId={transaction_id}
                activeStep={handleStepperForInvestment(investment)}
              />
            )
          })}
      </div>
    </Card>
  )
}

export default OpportunityProgressCard
