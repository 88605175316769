import { SupportedFileType } from '../types'

export const XS = 0
export const SM = 576
export const MD = 768
export const LG = 992
export const XL = 1200
export const XXL = 1400

export const breakpoints = {
  0: 'xs',
  576: 'sm',
  768: 'md',
  992: 'lg',
  1200: 'xl',
  1400: 'xxl',
}

// Asset Classes
export const ASSET_CLASSES = {
  PRIVATE_EQUITY: 'Private Equity',
  REAL_ESTATE: 'Real Estate',
  FUNDS_OF_FUNDS: 'Funds of Funds',
  FIXED_INCOME: 'Fixed Income',
  LISTED_EQUITIES: 'Listed Equities',
  TRADE_FINANCE: 'Trade Finance',
  MUTUAL_FUNDS: 'Mutual Funds',
}

export const ASSET_RETURNS_TITLE = {
  [ASSET_CLASSES.TRADE_FINANCE]: 'Expected YTM',
  [ASSET_CLASSES.FIXED_INCOME]: 'Annual Interest',
  [ASSET_CLASSES.PRIVATE_EQUITY]: 'Valuation',
  [ASSET_CLASSES.REAL_ESTATE]: 'Expected Return',
  [ASSET_CLASSES.FUNDS_OF_FUNDS]: 'Expected Return',
}

// Posthog Event names
export const EVENTS = {
  BUTTON_CLICKED: 'BUTTON_CLICKED',
  CUSTOM_EVENT: 'CUSTOM_EVENT',
  COMPLETED_INVESTMENT_PAYMENT: 'COMPLETED_INVESTMENT_PAYMENT',
  ERROR_OCCURRED: 'ERROR_OCCURRED',
  FEATURE_USED: 'FEATURE_USED',
  FEEDBACK_SUBMITTED: 'FEEDBACK_SUBMITTED',
  FORM_SUBMITTED: 'FORM_SUBMITTED',
  FILLED_INVESTOR_DETAIL: 'FILLED_INVESTOR_DETAIL',
  HOME_PAGE_VIEWED: 'HOME_PAGE_VIEWED',
  INVESTMENT_COMPLETED: 'INVESTMENT_COMPLETED',
  INVESTMENT_STARTED: 'INVESTMENT_STARTED',
  INVESTOR_COMPLETED_ONBOARDING: 'INVESTOR_COMPLETED_ONBOARDING',
  INVESTMENT_AMOUNT_ENTERED: 'INVESTMENT_AMOUNT_ENTERED',
  INVESTOR_LOGGED_IN: 'INVESTOR_LOGGED_IN',
  INVESTOR_LOGGED_OUT: 'INVESTOR_LOGGED_OUT',
  INVESTOR_SIGNED_UP: 'INVESTOR_SIGNED_UP',
  PAGE_VIEWED: 'PAGE_VIEWED',
  PREFERENCE_CHANGED: 'PREFERENCE_CHANGED',
  PROFILE_UPDATED: 'PROFILE_UPDATED',
  PROJECT_ADDED_TO_PORTFOLIO: 'PROJECT_ADDED_TO_PORTFOLIO',
  PROJECT_SELECTED: 'PROJECT_SELECTED',
  PROJECT_VIEWED: 'PROJECT_VIEWED',
  SEARCH_PERFORMED: 'SEARCH_PERFORMED',
  UPLOADED_AGREEMENT: 'UPLOADED_AGREEMENT',
  NAVIGATED_TO_HOME: 'NAVIGATED_TO_HOME',
  NAVIGATED_TO_PROJECTS: 'NAVIGATED_TO_PROJECTS',
  NAVIGATED_TO_SAVED_PROJECTS: 'NAVIGATED_TO_SAVED_PROJECTS',
  NAVIGATED_TO_FAQ: 'NAVIGATED_TO_FAQ',
  NAVIGATED_TO_DASHBOARD: 'NAVIGATED_TO_DASHBOARD',
  NAVIGATED_TO_PROFILE: 'NAVIGATED_TO_PROFILE',
  NAVIGATED_TO_LOGOUT: 'NAVIGATED_TO_LOGOUT',
  NAVIGATED_TO_LOG_IN: 'NAVIGATED_TO_LOG_IN',
  NAVIGATED_TO_SIGN_UP: 'NAVIGATED_TO_SIGN_UP',
  TERMS_CLICKED: 'TERMS_CLICKED',
  ABOUT_US_CLICKED: 'ABOUT_US_CLICKED',
  PRIVACY_POLICY_CLICKED: 'PRIVACY_POLICY_CLICKED',

  // v1 Events
  navigated_to_home: 'navigated_to_home',
  navigated_to_opportunities: 'navigated_to_opportunity',
  navigated_to_faqs: 'navigated_to_faqs',
  navigated_to_dashboard: 'navigated_to_dashboard',
  navigated_to_login_page: 'navigated_to_login_page',
  navigated_to_signup_page: 'navigated_to_signup_page',
  navigated_to_profile: 'navigated_to_profile',

  user_visited_credibila: 'user_visited_credibila',
  user_signed_up: 'user_signed_up',
  email_verification_link_sent: 'email_verification_link_sent',
  email_verified: 'email_verified',
  reset_password_link_sent: 'reset_password_link_sent',
  password_reset: 'password_reset',
  user_logged_in: 'user_logged_in',
  opportunity_searched: 'opportunity_searched',
  opportunity_page_visited: 'opportunity_page_visited',
  investment_started: 'investment_started',
  agreement_signed: 'agreement_signed',
  investment_application_submitted: 'investment_application_submitted',
  user_logged_out: 'user_logged_out',
  investment_approved_by_admin: 'investment_approved_by_admin',
}

export const ERROR_EVENTS = {
  ERROR_OCCURRED: 'ERROR_OCCURRED',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  AUTHORIZATION_ERROR: 'AUTHORIZATION_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  SERVER_ERROR: 'SERVER_ERROR',
  CLIENT_ERROR: 'CLIENT_ERROR',
  TIMEOUT_ERROR: 'TIMEOUT_ERROR',
}

export const CONTACT_US_ASSISTANCE_CATEGORIES = [
  { text: 'Platform', value: 'Platform' },
  { text: 'Account', value: 'Account' },
  { text: 'Investments', value: 'Investments' },
  { text: 'Verification and KYC', value: 'Verification and KYC' },
  { text: 'Refunds', value: 'Refunds' },
  { text: 'Urgent Request', value: 'Urgent Request' },
  // { text: 'My issue is not listed', value: 'My issue is not listed' }, // Commented out as per CB-130
]

export const SUPPORTED_FILE_UPLOAD_MIME_TYPES: { [key in SupportedFileType]: string } = {
  doc: '.doc, .docx',
  xls: '.xls, .xlsx',
  image: 'image/*',
  pdf: '.pdf',
}

export const DASHBOARD_VIEW_OPTIONS: string[] = ['portfolioSummary', 'activeInvestment']

// dashboard
export const HOLDINGS_TABLE_HEADERS: { [key: string]: string } = {
  current_price: 'Current Price (in USD)',
  invested_amount: 'Amount Invested (in USD)',
  total_return: 'Total Returns (in USD)',
  total_gains: 'Gains (in %)',
}

export const TRANSACTIONS_TABLE_HEADERS: { [key: string]: string } = {
  transaction_date: 'Transaction Date',
  transaction_type: 'Transaction Type',
  amount: 'Amount (in USD)',
}
