import React from 'react'
import { Card, H5 } from '../../lib/components'
import { PieChartCard } from './chart-card'
import { DashboardInvestmentDistribution } from '../../lib/types/propsType_v1'

const DistributionGraph = ({
  distributionData,
}: {
  distributionData: DashboardInvestmentDistribution | undefined
}) => {
  return (
    <Card classes={['info-card']}>
      <div className='info-card-heading'>
        <H5 text='Investments Distribution' />
      </div>
      <div className='info-card-body'>
        <PieChartCard
          data={distributionData}
          emptyMessage="No data to show. Looks like you don't have any holdings."
        />
      </div>
    </Card>
  )
}

export default DistributionGraph
