import React, { useCallback } from 'react'
import { SelectDropdownProps } from '../../types'
import './select-dropdown-component.scss'
import { concatClassName } from '../../utils/helpers'
import { ERROR, SUCCESS } from '../../utils/message'
import { StatusLabel } from '../StatusLabel'
import { CheckCircleIcon, ReportIcon } from '../Icon/icon-component'

const SelectDropdown = ({
  classes,
  list,
  name,
  formId,
  handleChange,
  id,
  errors,
  touched,
  dirty,
  value,
  defaultValue,
  disabled = false,
  placeholder,
}: SelectDropdownProps) => {
  const isError = errors && errors[name] && touched && touched[name]
  const isValid = !errors && dirty
  const getMessageType = useCallback(() => {
    if (isValid) {
      return SUCCESS
    }
    if (isError) {
      return ERROR
    }
    return SUCCESS
  }, [errors])
  return (
    <>
      <select
        className={` ${concatClassName(classes)}`}
        disabled={disabled}
        id={id}
        name={name}
        form={formId}
        value={value}
        defaultValue={defaultValue}
        onChange={(e: any) => handleChange && handleChange(e.target.value)}
      >
        {placeholder && (
          <option key={placeholder} value={''} disabled hidden>
            {placeholder}{' '}
          </option>
        )}
        {list &&
          list.map((data, index) => {
            return (
              <option key={index} value={data.value}>
                {data.text}{' '}
              </option>
            )
          })}
      </select>
      <span className='input-icon'>
        {isValid && <CheckCircleIcon height='20' width='20' type={getMessageType()} />}
        {isError && <ReportIcon height='20' width='20' type={getMessageType()} />}
      </span>
      {isError && <StatusLabel type={getMessageType()} variant='basic' text={errors[name]} />}
    </>
  )
}

export { SelectDropdown }
