import React, { useEffect, useState } from 'react'
import {
  AnchorLabel,
  Button,
  DollarIcon,
  Flex,
  H2,
  LeftArrowIcon,
  LocationIcon,
  Span,
  Tag,
} from '../../lib/components'
import { useNavigate, useParams } from 'react-router-dom'
import { BACK_TO_DASHBOARD } from '../../lib/utils/message'
import { projectServices } from '../../services'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { ASSET_CLASSES } from '../../lib/utils/constants'
import { displayDollar, displayPercentage, displayReturnsTitle } from '../../lib/utils/helpers'

const SpecificInvestmentInfoSection = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { opportunityId } = params
  const { authToken } = useSelector((state: RootState) => state.investor)
  const [opportunity, setOpportunity] = useState<any>()
  const backToProjectHandler = () => navigate('/dashboard')

  useEffect(() => {
    const fetchOpportunityById = async () => {
      const data: any = await projectServices.getOpportunityById(opportunityId as string, {
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        const opportunity = data.data
        setOpportunity(opportunity)
      }
    }
    if (opportunityId !== 'undefined') fetchOpportunityById()
    else navigate('/')
  }, [])

  return (
    <div className='specific-investment-info-section-container'>
      <Flex classes={['back-button-container']}>
        <Button
          classes={['auto-small-btn-width']}
          variant='secondary-btn'
          handleClick={backToProjectHandler}
          IconComponent={<LeftArrowIcon />}
        />
        <Span classes={['ml-16', 'font-16', 'back-button']} text={BACK_TO_DASHBOARD} />
      </Flex>
      {!opportunity ? (
        <Flex classes={['row-center', 'margin-y-auto']}>
          <Span variant='large' classes={['left', 'loading-text']} text='Loading Data...' />
        </Flex>
      ) : (
        <div className='specific-investment-info-section-container-body'>
          <div>
            <Flex classes={['mt-24', 'flex-align-center']}>
              <H2
                classes={['mr-16 specific-investment-info-section-container-heading']}
                text={opportunity?.projectName || ''}
              />
            </Flex>
            <Flex classes={['mt-16', 'align-center']}>
              <AnchorLabel
                href={''}
                text={opportunity?.location || ''}
                isDisabled={true}
                variant={'label-anchor'}
                IconComponent={<LocationIcon />}
              />

              <AnchorLabel
                classes={['ml-8']}
                href={''}
                text={opportunity?.currencyCode || ''}
                isDisabled={true}
                variant={'label-anchor'}
                IconComponent={<DollarIcon />}
              />

              {opportunity?.assetClass && (
                <Tag classes={['ml-8', 'asset-class-tag']} text={`${opportunity?.assetClass}`} />
              )}
            </Flex>
          </div>
          <div className='specific-investment-info-section-container-stats'>
            {opportunity?.assetClass !== ASSET_CLASSES.LISTED_EQUITIES &&
              opportunity?.irr !== '0.00' && (
                <div className='dashboard-specific-investment-investment-stats-card'>
                  <p className='dashboard-specific-investment-investment-card-main'>
                    {opportunity?.assetClass === ASSET_CLASSES.PRIVATE_EQUITY
                      ? displayDollar(opportunity?.irr, opportunity?.currencyCode)
                      : displayPercentage(opportunity?.irr) || ''}
                  </p>
                  <p className='dashboard-specific-investment-investment-card-sub'>
                    {displayReturnsTitle(opportunity?.assetClass)}
                  </p>
                </div>
              )}
            <div className='dashboard-specific-investment-investment-stats-card'>
              <p className='dashboard-specific-investment-investment-card-main'>
                {displayDollar(opportunity?.ticketSize, opportunity?.currencyCode)}
              </p>
              <p className='dashboard-specific-investment-investment-card-sub'>Ticket Size</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SpecificInvestmentInfoSection
