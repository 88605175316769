import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../lib/components'
import { kycServices } from '../../services'
import { RootState } from '../../store'

interface SumSubProps {
  kycStatus: string | undefined
  noOfKyc: number
  getKYC: () => Promise<void>
}

const SumSub = ({ kycStatus, noOfKyc, getKYC }: SumSubProps) => {
  const MAX_RETRY = 3
  const POLL_STATUS_INTERVAL = 3000 //ms
  const { authToken, userId } = useSelector((state: RootState) => state.investor)
  const [SumsubExternalLink, setSumsubExternalLink] = useState('')
  const [KYCLinkText, setKYCLinkText] = useState('Start')

  const fetchToken = async () => {
    const response: any = await kycServices.generateSumsubSDKLink(
      {
        Authorization: authToken ? authToken : '',
      },
      userId,
      'INDIVIDUAL',
    )
    const { url } = response.data
    setSumsubExternalLink(url)
  }

  const handleButtonClick = async () => {
    if (kycStatus === 'REJECTED') {
      const data: any = {
        acceptedTNC: true,
        acceptedNDA: true,
      }

      const response: any = await kycServices.acceptTNCAndNDA_v1(data, {
        authorization: authToken ? authToken : '',
      })

      if (response && response.status === 200) {
        await fetchToken()
        await getKYC()
      }
    }

    window.open(SumsubExternalLink, '_blank')
  }

  useEffect(() => {
    const intervalID = setInterval(async () => {
      const shouldPollStatus =
        (kycStatus !== 'APPROVED' && kycStatus !== 'REJECTED') ||
        (kycStatus === 'REJECTED' && noOfKyc < MAX_RETRY)

      if (shouldPollStatus) {
        await getKYC()
      }
    }, POLL_STATUS_INTERVAL)

    return () => clearInterval(intervalID)
  }, [kycStatus, noOfKyc])

  useEffect(() => {
    if (kycStatus === 'NOT_STARTED') {
      setKYCLinkText('Start')
    } else if (kycStatus === 'INITIATED') {
      setKYCLinkText('Continue')
    } else if (kycStatus === 'RESUBMIT') {
      setKYCLinkText('Continue')
    } else if (kycStatus === 'REJECTED') {
      setKYCLinkText('Start Again')
    }
    fetchToken()
  }, [kycStatus])

  if (
    ['PENDING', 'APPROVED'].includes(kycStatus ?? 'NOT_STARTED') ||
    (kycStatus === 'REJECTED' && noOfKyc >= MAX_RETRY)
  ) {
    return <></>
  }

  return (
    <div>
      <div>
        {kycStatus !== 'REJECTED' && kycStatus !== 'RESUBMIT' && (
          <div className='kyc-sub-heading-text-container-note mb-16'>
            <p className='basic kyc-sub-heading-text-note'>
              During your KYC, you can always leave anytime and return to Home Page. Your progress
              is saved so you continue from where you left.
            </p>
          </div>
        )}
      </div>
      <Button
        classes={['auto-btn-width']}
        variant='primary-btn'
        text={KYCLinkText}
        handleClick={handleButtonClick}
      />
    </div>
  )
}

export { SumSub }
